export enum StorageTankOptions {
  Travel,
  Stationary,
  Both,
}

export enum ManagementColumnFilterTypes {
  ACTIVE = 'active eq true;',
  INACTIVE = 'active neq true;',
  BOTH = '',
}
