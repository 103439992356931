import { FC } from 'react';
import { RSG_CONSTANTS, URL_CONSTANTS } from '../../constants/common';
import './termsAndConditions.scss';

type TermsAndConditionsProps = {
  acceptedTerms: boolean;
  setAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
  openTermsModal: () => void;
};

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ acceptedTerms, setAcceptedTerms, openTermsModal }) => {
  return (
    <div className="terms-conditions">
      <input
        type="checkbox"
        id="terms"
        name="terms"
        value="terms"
        checked={acceptedTerms}
        onChange={() => setAcceptedTerms(!acceptedTerms)}
      />
      <p>
        By checking this box you are agreeing to &nbsp;
        <b onClick={() => openTermsModal()}>{RSG_CONSTANTS.RSG_STORAGE_RATES}</b> , &nbsp;
        <a href={URL_CONSTANTS.TERMS_OF_USE_URL} target="_blank">
          <b>{RSG_CONSTANTS.TERMS_OF_USE}</b>
        </a>
        &nbsp; , and &nbsp;
        <a href={URL_CONSTANTS.PRIVACY_POLICY_URL} target="_blank">
          <b>{RSG_CONSTANTS.PRIVACY_POLICY}</b>
        </a>
        .
      </p>
    </div>
  );
};

export default TermsAndConditions;
