import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../../components';
import PagedTable from '../../components/sorted-table/PagedTable';
import AnimalManagementHeader from './AnimalManagementHeader';
import { getAnimals } from '../../api/animalsApi';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { showToast } from '../../services/toast.service';
import { Animal } from '../../types/interfaces';

const ManageAnimals = (): JSX.Element => {
  const animalsFetchLimit = 20;

  const [animalsData, setAnimalsData] = useState<Animal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const callNumber: MutableRefObject<number> = useRef<number>(0);
  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const ref = useRef<any>(null);

  const loadData = async () => {
    callNumber.current++;
    if (hasAllResults.current) {
      return;
    }
    const callNum = callNumber.current;
    try {
      setLoading(true);

      const query = { include: 'Breed.Species', sort: 'AnimalId', limit: animalsFetchLimit, offset: offset };
      const response = await getAnimals(query);
      const animals = response.data as Animal[];

      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }

      if (callNum === callNumber.current) {
        const newTableData = offset > 0 ? [...animalsData, ...animals] : animals;
        setAnimalsData(newTableData);
        setLoading(false);
        hasAllResults.current = animals.length === 0;
      }
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    window.innerWidth <= 767 && loadData();
  }, [offset]);

  useEffect(() => {
    window.addEventListener('touchmove', handleScroll);
    return () => window.removeEventListener('touchmove', handleScroll);
  }, []);

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [animalsData]);

  const loadMore = () => {
    setOffset(prevState => prevState + animalsFetchLimit);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const position = scrollHeight - scrollTop;
    const atBottom = position - clientHeight <= clientHeight;

    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  return (
    <div className="animal-management">
      <AnimalManagementHeader />
      <div className="desk-animal-management">
        <div className="card">
          <PagedTable
            headers={[
              { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_ID, sortValue: 'AnimalId' },
              { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_CODE, sortValue: 'Code' },
              { displayName: TABLE_HEADER_CONSTANTS.NAME, sortValue: 'Name' },
              { displayName: TABLE_HEADER_CONSTANTS.GENDER, sortValue: 'Gender' },
              { displayName: TABLE_HEADER_CONSTANTS.SPECIES_BREED },
              { displayName: '' },
            ]}
            include="Breed.Species"
            getData={getAnimals}
            sortBy="AnimalId"
            buildRow={animal => {
              return [
                animal.animalId,
                animal.code,
                animal.name,
                animal.gender,
                `${animal.breed.species.name}-${animal.breed.name}`,
                <Link to={ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animal.animalId}>{BUTTON_CONSTANTS.VIEW}</Link>,
              ];
            }}
            pageSize={10}
            height={500}
          />
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-animal-management">
        {!loading && animalsData.length === 0 ? (
          <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
        ) : (
          animalsData?.length > 0 &&
          animalsData &&
          animalsData.map((animal, index) => {
            return (
              <div className="card max-width" key={'animal-' + animal?.code + index}>
                <div className="animal-management-card">
                  <div className="animal-management-content">
                    <div className="card-content-section">
                      <div className="animal-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL_ID} : `}</label>
                        <label>{`${animal.animalId ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL_CODE} : `}</label>
                        <label>{`${animal.code ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.NAME} : `}</label>
                        <label>{`${animal.name ?? '-'}`}</label>
                      </div>
                    </div>
                    <div className="card-content-section">
                      <div className="animal-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.GENDER} : `}</label>
                        <label>{`${animal.gender ?? '-'}`}</label>
                      </div>
                      <div className="animal-content">
                        <label>{`${TABLE_HEADER_CONSTANTS.SPECIES_BREED} : `}</label>
                        <label>{`${animal?.breed?.species ? animal?.breed?.species.name + '-' + animal.breed.name : '-'}`}</label>
                      </div>
                    </div>
                  </div>
                  <Link className="view-animal-link" to={ROUTE_PATHS.APP_ANIMAL_MANAGEMENT_ + animal.animalId}>
                    {BUTTON_CONSTANTS.VIEW}
                  </Link>
                </div>
              </div>
            );
          })
        )}
        {loading && <Loader addedSpace loaderSize="small" />}
      </div>
    </div>
  );
};

export default ManageAnimals;
