import { ChangeEvent, FC, useState, DragEvent } from 'react';
import Modal from '../modal/Modal';
import { UploadImageSVG } from '../svgs';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import { showToast } from '../../services/toast.service';
import './uploadImageModal.scss';

type UploadImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  postImage: (formData: FormData) => Promise<void>;
};

const UploadImageModal: FC<UploadImageModalProps> = ({ isOpen, onClose, postImage }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const validFileTypes = ['image/jpeg', 'image/png'];

  const validateFiles = (file: File) => {
    return validFileTypes.includes(file.type);
  };

  const fileChangedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return;
    }
    if (!validateFiles(file)) {
      showToast.error(toastMessages.INVALID_FILE_TYPE);
      return;
    }
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    if (!file) {
      return;
    }

    if (!validateFiles(file)) {
      showToast.error(toastMessages.INVALID_FILE_TYPE);
      return;
    }
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const resetHandler = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    onClose();
  };

  const uploadHandler = async () => {
    if (!selectedFile) {
      return;
    }

    const maxSize = 3000000;
    if (selectedFile.size > maxSize) {
      showToast.error(toastMessages.IMAGE_LARGER_THAN_3MB);
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile, selectedFile.name);
    try {
      await postImage(formData);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      resetHandler();
    }
  };

  return (
    <Modal isOpen={isOpen} ignoreBackdrop onClose={onClose} width={557}>
      <div className="upload-modal-content">
        <h2>{LABEL_CONSTANTS.UPLOAD_IMAGE}</h2>
        <br />
        <div
          className="drop-area"
          data-testid="drop-area"
          onDragOver={event => event.preventDefault()}
          onDrop={onDrop}
          style={{ backgroundImage: previewUrl ? `url(${previewUrl})` : 'none' }}>
          {!previewUrl.length && (
            <>
              <UploadImageSVG className="upload-icon" />
              <br />
              <p className="drag-n-drop-text">{LABEL_CONSTANTS.DRAG_DROP_FILE}</p>
              <p className="file-types-text">{LABEL_CONSTANTS.PNG_JPG_JPEG}</p>
            </>
          )}
        </div>
        <br />
        <div className="file-select">
          <button className="red button medium" onClick={resetHandler}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          <label htmlFor="uploadFile" className="button green upload-label">
            {BUTTON_CONSTANTS.CHOOSE_FILES}
          </label>
          <input id="uploadFile" type="file" accept="image/*" data-testid="Choose-Files" onChange={fileChangedHandler} multiple />
          <br />
          <button className="green button medium" onClick={uploadHandler}>
            {BUTTON_CONSTANTS.UPDATE}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
