import { FC, useState } from 'react';
import Modal from '../modal/Modal';
import Loader from '../loader/Loader';
import { signOutUser } from '../../services/auth.service';
import { showToast } from '../../services/toast.service';
import { resendVerificationEmail } from '../../api/userApi';
import { toastMessages } from '../../constants/errorMessages';
import { ACCOUNT_CONSTANTS, BUTTON_CONSTANTS } from '../../constants/common';

type VerifyUserModalProps = {
  isOpen: boolean;
  userId: number;
};

const VerifyUserModal: FC<VerifyUserModalProps> = ({ isOpen, userId }) => {
  const [resendingLink, setResendingLink] = useState<boolean>(true);

  const handleResend = async () => {
    if (!resendingLink) return;
    setResendingLink(false);

    try {
      await resendVerificationEmail(userId);
      showToast.success(toastMessages.INVITE_RESENT);
    } catch {
      showToast.error(toastMessages.FAILED_TO_RESEND_INVITE);
    } finally {
      setResendingLink(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} width={700}>
      <div className="sign-out-modal">
        <div className="header">
          <h4>{ACCOUNT_CONSTANTS.VERIFY_ACCOUNT}</h4>
        </div>
        <div className="body">
          {!resendingLink && <Loader loaderSize="small" simple />}
          <p>{ACCOUNT_CONSTANTS.VERIFY_USER_EMAIL}</p>
        </div>
        <div className="footer">
          <button className="button blue inverted medium" onClick={() => signOutUser()}>
            {BUTTON_CONSTANTS.SIGN_OUT}
          </button>
          <button className="button green medium" onClick={() => handleResend()}>
            {BUTTON_CONSTANTS.RESEND_LINK}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyUserModal;
