import { localStorageConstants } from '../constants/localStorage.constants';
import { toastMessages } from '../constants/errorMessages';
import { showToast } from '../services/toast.service';

const { REFRESH_TOKEN, REFRESH_TOKEN_EXPIRY, ACCESS_TOKEN, USER, ACTIVE_ACCOUNT_ID } = localStorageConstants;

export const getLocalUser = () => {
  return JSON.parse(localStorage.getItem(USER) || sessionStorage.getItem(USER) || '{}');
};

export const setLocalUser = (userId: number, username: string, staySignedIn: boolean) => {
  if (staySignedIn) {
    localStorage.setItem(USER, JSON.stringify({ userId, username }));
  } else {
    sessionStorage.setItem(USER, JSON.stringify({ userId, username }));
  }
};

export const getLocalRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setLocalRefreshToken = (refreshToken: string, staySignedIn: boolean) => {
  if (staySignedIn) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const getLocalAccessToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN);
};

export const setLocalAccessToken = (token: string) => {
  sessionStorage.setItem(ACCESS_TOKEN, token);
};

export const getLocalRefreshTokenExpiry = () => {
  return localStorage.getItem(REFRESH_TOKEN_EXPIRY);
};

export const setLocalRefreshTokenExpiry = (refreshTokenExpiry: Date | string, staySignedIn: boolean) => {
  if (staySignedIn) {
    localStorage.setItem(REFRESH_TOKEN_EXPIRY, refreshTokenExpiry.toString());
  }
};

export const getLocalActiveAccountId = () => {
  return Number(sessionStorage.getItem(ACTIVE_ACCOUNT_ID)) || undefined;
};

export const setLocalActiveAccountId = (activeAccountId: number | string) => {
  sessionStorage.setItem(ACTIVE_ACCOUNT_ID, activeAccountId.toString());
};

export const clearLocalData = () => {
  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch (err) {
    showToast.error(toastMessages.LOCAL_SESSION_STORAGE_CLEAR_FAIL);
  }
};
