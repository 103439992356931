import { CSSProperties, FC, useLayoutEffect, useEffect, useRef, useState } from 'react';
import './actionButtons.scss';

interface ActionMenuProps {
  actionButtons?: ActionButton[];
}

export type ActionButton = {
  name: string;
  action?: () => void;
};

const ActionMenu: FC<ActionMenuProps> = ({ actionButtons }: ActionMenuProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isFading, setIsFading] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [position, setPosition] = useState('bottom');
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const tooltipRefs = useRef<HTMLSpanElement | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  function handleSpanCSS(): CSSProperties {
    const styles: CSSProperties = {
      visibility: isVisible ? 'visible' : 'hidden',
      transition: isVisible ? 'ease opacity 1s' : 'none',
      pointerEvents: isVisible ? 'auto' : 'none',
      top: position === 'bottom' ? '100%' : position === 'left' ? '' : `-${tooltipHeight}px`,
    };

    return styles;
  }

  const handleMouseEnter = () => {
    window.innerWidth > 767 && setHovering(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }

    if (isComplete) setIsComplete(false);

    if (isFading) setIsFading(false);
  };

  const handleMouseLeave = () => {
    setHovering(false);
    if (!timeoutId.current)
      timeoutId.current = setTimeout(() => {
        setIsFading(true);
      }, 1000);
  };

  function handleTransitionEnd() {
    if (isFading || window.innerWidth <= 767) {
      setIsVisible(false);
      setIsFading(false);
      setIsComplete(true);
    }
  }

  function handleButtonClick() {
    setIsVisible(true);
    if (tooltipRefs.current) {
      setTooltipHeight(tooltipRefs.current.getBoundingClientRect().height);
    }
  }

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, []);

  useLayoutEffect(() => {
    const tooltipNode = tooltipRefs.current;
    const tableNode = tooltipNode?.closest('tbody');

    const handlePositionAdjustment = () => {
      if (tooltipNode && tableNode) {
        const tableRect = tableNode.getBoundingClientRect();
        const tooltipRect = tooltipNode.getBoundingClientRect();
        const tooltipBottomWhenPositionIsTop = tooltipRect.bottom + tooltipRect.height;
        const tooltipTopWhenPositionIsTop = tooltipRect.top - tooltipRect.height;
        const space = 10;
        const tooltipTextTopScssValue = 32;

        if (tableNode?.childElementCount === 1) {
          setPosition('left');
        } else if (
          position === 'bottom' &&
          tooltipRect.bottom >= tableRect.bottom - space &&
          tooltipTopWhenPositionIsTop >= tableRect.top
        ) {
          setPosition('top');
        } else if (position === 'top' && tooltipBottomWhenPositionIsTop >= tableRect.bottom - space - tooltipTextTopScssValue) {
          setPosition('top');
        } else {
          setPosition('bottom');
        }
      }
    };

    if (isVisible) {
      handlePositionAdjustment();
    }
  }, [actionButtons, isVisible]);

  return (
    <button
      className={`action-tooltip transaction-tooltip tooltip button icon ${hovering || isVisible ? 'hovering' : ''} ${
        isVisible || isFading || isComplete ? '' : 'hide-overflow'
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleButtonClick}
      onBlur={() => setIsVisible(false)}
      data-testid={'action-button'}>
      <div className="transaction-tooltip-label">...</div>
      <span
        data-testid={'action-button-span'}
        className={`action-tooltip-text ${isFading ? 'fading' : ''} ${
          position === 'top' ? 'top' : position === 'left' ? 'left' : ''
        }`}
        style={handleSpanCSS()}
        ref={ref => {
          tooltipRefs.current = ref;
        }}
        onTransitionEnd={handleTransitionEnd}>
        {!isComplete &&
          actionButtons?.length &&
          actionButtons.map((button, index) => {
            return (
              <div className="container" key={index + button.name}>
                {index > 0 && <hr />}
                <div className="action-button" key={index + button.name} onClick={button.action}>{`${button.name}`}</div>
              </div>
            );
          })}
      </span>
    </button>
  );
};

export default ActionMenu;
