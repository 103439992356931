import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import PaymentHistory from './PaymentHistory';
import PaymentInformation from '../PaymentInformation';
import { StoragePlanCardProps } from '../../../pages/auth/storage-plans/storage-plan-card/StoragePlanCard';
import AddPaymentMethodModal, { initialPaymentState } from '../../payment-method-modal/AddPaymentMethodModal';
import Loader from '../../loader/Loader';
import { EditSVG } from '../../svgs';
import { showToast } from '../../../services/toast.service';
import { getPaymentDetailsByAccountId } from '../../../api/paymentGatewayApi';
import { getStoragePlans } from '../../../api/storagePlansApi';
import { AddressType, PaymentMethods } from '../../../types/enums';
import { PaymentMethod } from '../../../types/interfaces/paymentMethod.interface';
import { toastMessages } from '../../../constants/errorMessages';
import {
  ADDRESS_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAYMENT_CONSTANTS,
  STORAGE_PLAN_CONSTANTS,
} from '../../../constants/common';
import './manageBillingInformation.scss';

type ManageBillingInformationProps = {
  locationState: { activeTab: number; addPaymentMethodModalVisible: boolean; instantPayment?: boolean };
};

const ManageBillingInformation: FC<ManageBillingInformationProps> = ({ locationState }) => {
  const navigate = useNavigate();

  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const activeAccount = useAppSelector(state => state.activeAccount);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const user = useAppSelector(state => state.user);

  const [activeTab, setActiveTab] = useState(0);
  const [addPaymentMethodModalVisible, setAddPaymentMethodModalVisible] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>(initialPaymentState);
  const [storageRates, setStorageRates] = useState([
    { storagePlanId: 1, name: '', costInCents: 0, unitOfMeasurement: '', period: '' },
  ]);
  const [storageRatesLoading, setStorageRatesLoading] = useState<boolean>(false);
  const [updatedPaymentInfo, setUpdatedPaymentInfo] = useState<boolean>(false);

  const handleRates = async () => {
    let rates: StoragePlanCardProps[] = [];

    try {
      setStorageRatesLoading(true);
      rates = (await getStoragePlans()).data;
      if (rates) {
        setStorageRates(rates);
        setStorageRatesLoading(false);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getPaymentDetails = async () => {
    if (accountId && paymentStatus && activeAccount?.billingEnabled) {
      try {
        const { data: paymentDetailsResponse } = await getPaymentDetailsByAccountId(accountId);
        let accountData =
          paymentDetailsResponse?.paymentMethod === PaymentMethods.CARD
            ? {
                cardNumber: {
                  value: paymentDetailsResponse?.maskedCardNumber,
                  valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                },
                expiry: {
                  value: paymentDetailsResponse?.expiry,
                  valid: paymentDetailsResponse?.expiry?.length > 0 ? true : false,
                },
                accountNumber: { value: '', valid: false },
                accountType: { value: '', name: '' },
              }
            : {
                cardNumber: { value: '', valid: false },
                expiry: { value: '', valid: false },
                accountNumber: {
                  value: paymentDetailsResponse?.maskedCardNumber,
                  valid: paymentDetailsResponse?.maskedCardNumber?.length > 0 ? true : false,
                },
                accountType: { value: paymentDetailsResponse?.accountType, name: paymentDetailsResponse?.accountType },
              };

        setPaymentInfo({
          paymentType: {
            value: paymentDetailsResponse?.paymentMethod,
            valid: paymentDetailsResponse?.paymentMethod ? true : false,
          },
          name: { value: paymentDetailsResponse?.name, valid: paymentDetailsResponse?.name?.length > 0 ? true : false },
          address: { value: paymentDetailsResponse?.address, valid: paymentDetailsResponse?.address?.length > 0 ? true : false },
          city: { value: paymentDetailsResponse?.city, valid: paymentDetailsResponse?.city?.length > 0 ? true : false },
          region: { value: paymentDetailsResponse?.region, valid: paymentDetailsResponse?.region?.length > 0 ? true : false },
          postalCode: { value: paymentDetailsResponse?.postal, valid: paymentDetailsResponse?.postal?.length > 0 ? true : false },
          country: { value: paymentDetailsResponse?.country, valid: paymentDetailsResponse?.country?.length > 0 ? true : false },
          routingNumber: { value: '', valid: false },
          cvv: { value: '', valid: false },
          ...accountData,
        });
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setUpdatedPaymentInfo(false);
      }
    }
  };

  useEffect(() => {
    if (locationState != null) {
      setActiveTab(locationState?.activeTab);
      setAddPaymentMethodModalVisible(locationState?.addPaymentMethodModalVisible);
      navigate(location.pathname, { replace: true });
    }
  }, [locationState]);

  useEffect(() => {
    handleRates();
  }, []);

  useEffect(() => {
    getPaymentDetails();
  }, [updatedPaymentInfo]);

  const cardNumberFormat = () => {
    if (paymentInfo?.paymentType?.value === PaymentMethods.CARD) {
      return `xxxx xxxx xxxx ${paymentInfo?.cardNumber?.value?.slice(-4)}`;
    } else {
      let pattern = '';

      for (let index = 0; index < paymentInfo?.accountNumber?.value?.length - 4; index++) {
        pattern += 'x';
      }

      return `${pattern}${paymentInfo?.accountNumber?.value?.slice(-4)}`;
    }
  };

  const addPaymentMethod = () => {
    const accountHasAddresses = () => activeAccount && activeAccount?.addresses && activeAccount?.addresses?.length > 0;

    if (accountHasAddresses()) {
      const billing = activeAccount?.addresses!.find(address => address?.type === AddressType.BILLING);

      if (billing) {
        setPaymentInfo(paymentInfo => {
          return {
            ...paymentInfo,
            address: { value: billing?.streetAddress, valid: billing?.streetAddress?.length > 0 ? true : false },
            city: { value: billing?.city, valid: billing?.city?.length > 0 ? true : false },
            region: { value: billing?.state, valid: billing?.state?.length > 0 ? true : false },
            postalCode: { value: billing?.postalCode, valid: billing?.postalCode?.length > 0 ? true : false },
            country: { value: billing?.country, valid: billing?.country?.length > 0 ? true : false },
          };
        });
      }
    }

    setAddPaymentMethodModalVisible(true);
  };

  return (
    <>
      <div className="manage-billing-information">
        <h4 className="top-header">{LABEL_CONSTANTS.MANAGE_YOUR_BILLING}</h4>
        <div className="manage-billing-info__header">
          <ul className="manage-billing-info__tabs">
            <li className={activeTab === 0 ? 'active' : ''} data-testid="active-tab-0" onClick={() => setActiveTab(0)}>
              <div className="storage-rates">{LABEL_CONSTANTS.STORAGE_RATES}</div>
            </li>
            <li className={activeTab === 1 ? 'active' : ''} data-testid="active-tab-1" onClick={() => setActiveTab(1)}>
              <div className="payment-history">{LABEL_CONSTANTS.PAYMENT_HISTORY}</div>
            </li>
            <li className={activeTab === 2 ? 'active' : ''} data-testid="active-tab-2" onClick={() => setActiveTab(2)}>
              <div className="payment-method">{LABEL_CONSTANTS.PAYMENT_METHOD}</div>
            </li>
          </ul>
        </div>

        <div className="manage-billing-info__body">
          {activeTab === 0 && (
            <>
              {storageRatesLoading ? (
                <div className="storage-rates-loader">
                  <Loader addedSpace loaderSize="small" />
                </div>
              ) : (
                <PaymentInformation
                  header={''}
                  description={STORAGE_PLAN_CONSTANTS.STORAGE_RATES_SUBHEADER}
                  columnsData={storageRates?.map(storage => {
                    return {
                      storagePlanId: storage?.storagePlanId,
                      quantityHeader: storage?.name,
                      costHeader: storage?.costInCents,
                      unitOfMeasurement: storage?.unitOfMeasurement,
                      period: storage?.period,
                    };
                  })}
                />
              )}
            </>
          )}

          {activeTab === 1 && (
            <div className="payment-history">
              <PaymentHistory />
            </div>
          )}

          {activeTab === 2 && (
            <>
              {paymentStatus != undefined && activeAccount?.billingEnabled != undefined && activeAccount?.billingEnabled && (
                <>
                  {paymentStatus ? (
                    <div className="payment-method">
                      <div className="card">
                        <div className="payment-card-header">
                          <h4>
                            {paymentInfo?.paymentType?.value === PaymentMethods.CARD
                              ? PAYMENT_CONSTANTS.CARD
                              : paymentInfo?.paymentType?.value}
                          </h4>
                          <button
                            className="icon button transparent edit-button"
                            data-testid="edit-button"
                            onClick={() => setAddPaymentMethodModalVisible(true)}>
                            <EditSVG />
                          </button>
                        </div>
                        <div className="payment-card-body">
                          <div className="card-number">
                            <span>{cardNumberFormat()}</span>
                          </div>
                          <div className="billing-address">
                            <h5>{ADDRESS_CONSTANTS.ADDRESS}:</h5>
                            <p>
                              {paymentInfo?.address?.value}, {paymentInfo?.city?.value}, {paymentInfo?.region?.value}{' '}
                              {paymentInfo?.postalCode?.value} {paymentInfo?.country?.value}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="add-payment-method-button">
                        <button className="button outlined" onClick={() => addPaymentMethod()}>
                          {BUTTON_CONSTANTS.ADD_PAYMENT_METHOD}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {accountId && addPaymentMethodModalVisible && (
        <AddPaymentMethodModal
          title={paymentStatus ? LABEL_CONSTANTS.UPDATE_PAYMENT_METHOD : LABEL_CONSTANTS.ADD_PAYMENT_METHOD}
          accountId={accountId}
          paymentDetails={paymentInfo}
          isOpen={addPaymentMethodModalVisible}
          setIsOpen={setAddPaymentMethodModalVisible}
          onclick={() => setAddPaymentMethodModalVisible(false)}
          buttonAction={paymentStatus ? 'UpdatePaymentInfo' : 'AddPaymentInfo'}
          setUpdatedPaymentInfo={setUpdatedPaymentInfo}
        />
      )}
    </>
  );
};

export default ManageBillingInformation;
